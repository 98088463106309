<template>
	<div>
		<img class="img-bg" v-if="banner.length" :src="banner[0]" alt="">
		<div class="content-cls product-box">
			<div class="left-box">
				<div class="search-box">
					<input type="text" class="keyword-cls" v-model="params.search_data" placeholder="搜索关键字">
					<i class="el-icon-search" @click="searchFn"></i>
				</div>
				<div class="cete-list">
					<div class="cate-all" @click="searchCate(0)">所有产品</div>
					<div class="cate-item" :class="{'active-cls': item.id == params.c_id}" @click="searchCate(item.id)" v-for="item in cateList" :key="item.id">{{item.name}}</div>
				</div>
			</div>
			<div class="right-box">
				<div class="type-list">
					<div class="type-item" @click="chooseType(item.type)" :class="{'active-cls': item.type == params.type}" v-for="item in searchList" :key="item.type">{{item.name}}</div>
				</div>
				<div class="list-box">
					<div class="li-cls" v-for="(item) in showList" :key="item.id" @click="goDetail(item.id)">
						<img class="li-img" :src="item.img_id" alt="">
						<div class="li-bottom">
							<div class="flex-1">
								<div class="name-cls ellipsis-text">{{item.title}}</div>
								<div class="click-num">点击数：{{item.click_num}}</div>
							</div>
							<i class="el-icon-right"></i>
						</div>
					</div>
					<!-- <el-empty description="暂无该产品,请等待产品上线~"></el-empty> -->
				</div>
				
				<div class="product-bottom">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :page-size="params.pagesize"
					  :total="params.pagesize * pagenum"
					  @current-change="changePage">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				banner: [], // banner
				cateList: [], // 分类
				searchList: [], // 搜索类型
				currentType: null, // 搜索类型
				params: {
					page: 1,
					pagesize: 12,
					ip_id: '',
					c_id: '',
					type: 0,
					search_data: ''
				},
				showList: [],
				pagenum: null
			}
		},
		created() {
			this.params.ip_id = this.$route.params.id;
			this.getData();
			this.getProductList();
		},
		methods: {
			// 搜索
			searchFn() {
				this.params.page = 1;
				this.getProductList();
			},
			// 选中分类
			searchCate(id) {
				this.params.c_id = id ? id : '';
				this.searchFn();
			},
			// 选择类型
			chooseType(type) {
				this.params.type = type;
				this.searchFn();
			},
			// 获取数据
			getData() {
				this.$api.getIpCate({
					ip_id: this.params.ip_id
				}).then(res => {
					if (res.code == '0000') {
						this.banner = res.data.banner_list;
						this.cateList = res.data.cate;
						this.searchList = res.data.searchtype;
						// 默认选中第一个搜索类型
						this.currentType = res.data.searchtype[0];
					}
				})
			},
			// 获取产品列表
			getProductList() {
				this.$api.getIpProductList(this.params).then(res => {
					if (res.code == '0000') {
						this.pagenum = res.data.pagenum;
						this.showList = res.data.list;
					}
				})
			},
			// 跳转到详情
			goDetail(id) {
				this.$router.push({
					path: `/productDetail/${id}`
				})
			},
			changePage(e) {
				this.params.page = e;
				this.getProductList();
			}
		}
	}
</script>

<style scoped lang="scss">
	.product-box{
		padding: 70px 0;
		white-space: nowrap;
		display: flex;
		
		.left-box{
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
			width: 228px;
			height: auto;
			background: #FAFAFA;
			border: 1px solid #E6E6E6;
			border-radius: 2px;
			text-align: left;
			
			.search-box{
				width: 228px;
				background: #fff;
				border-bottom: 1px solid #e6e6e6;
				display: flex;
				font-size: 16px;
				
				.keyword-cls{
					padding-left: 20px;
					flex: 1;
					border: none;
					outline: none;
					width: 100px;
				}
				
				.el-icon-search{
					background: #ccc;
					width: 46px;
					text-align: center;
					line-height: 44px;
					cursor: pointer;
				}
			}
			
			.cete-list{
				font-size: 16px;
				color: #666;
				line-height: 44px;
				
				.cate-all{
					height: 44px;
					padding-left: 20px;
					border-bottom: 1px solid #e6e6e6;
					cursor: pointer;
				}
				
				.cate-item{
					height: 44px;
					cursor: pointer;
					padding-left: 30px;
				}
				
				.cate-item.active-cls{
					background: #eeeeee;
				}
				
				.cate-item:hover{
					background: #eeeeee;
				}
			}
		}
		
		.right-box{
			flex: 1;
			display: inline-block;
			width: 1018px;
			background: #FCFCFC;
			border: 1px solid #E6E6E6;
			padding-bottom: 20px;
			
			.type-list{
				display: flex;
				height: 44px;
				line-height: 44px;
				border-bottom: 1px solid #e6e6e6;
				
				.type-item{
					width: 120px;
					text-align: center;
					border-right: 1px solid #e6e6e6;
					cursor: pointer;
					background: #ccc;
					font-size: 16px;
					transition: all .3s;
				}
				
				.type-item.active-cls{
					background: #fff;
					color: #22529A;
				}
				
				.type-item:hover{
					background: #fff;
					color: #22529A;
				}
			}
			
			.list-box{
				margin-top: 20px;
				white-space: normal;
				text-align: left;
				padding: 0 19px;
				
				.li-cls{
					width: 230px;
					background: #FFFFFF;
					border-radius: 4px;
					margin: 0 20px 20px 0;
					box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
					display: inline-block;
					vertical-align: top;
					cursor: pointer;
					transition: all .3s;
				}
				
				
				.li-cls:nth-child(4n) {
					margin-right: 0;
				}
				
				.li-img{
					height: 210px;
					width: 210px;
					margin: 10px;
					transition: all .3s;
				}
				
				.li-bottom{
					border-top: 1px solid #f7f7f7;
					padding: 12px;
					display: flex;
					align-items: center;
				}
				
				.flex-1{
					flex: 1;
				}
				
				.name-cls{
					font-size: 16px;
					color: #666;
					width: 160px;
					height: 21px;
				}
				
				.click-num{
					font-size: 14px;
					color: #999;
				}
				
				.el-icon-right{
					font-size: 20px;
					color: #ccc;
					padding: 0 20px;
				}
			}
			
			
			.li-cls:hover{
				box-shadow: 0px 0px 6px 0px rgba(64, 158, 255, 0.5);
			}
			
			.li-cls:hover .li-img{
				transform: scale(1.05);
			}
			
			.product-bottom{
				text-align: right;
				padding-right: 30px;
			}
		}
	}
</style>
