<template>
	<div>
		<div class="img-bg-box" v-if="banner.length">
			<img class="img-bg" :src="banner[0]" alt="">
		</div>
		<div class="content-cls product-box">
			<!-- 筛选栏 -->
			<div class="screen-box" :class="{'show-search': show}">
				<div class="flex-box" v-if="cateList.length">
					<van-dropdown-menu active-color="#1989fa">
						<van-dropdown-item v-model="params.c_id" :options="cateList" @change="searchFn" />
						<van-dropdown-item v-model="params.type" :options="searchList" @change="searchFn" />
					</van-dropdown-menu>
					<van-icon name="search" class="icon-search" size="22" @click="showSearch" />
				</div>
				<div class="search-box">
					<van-search v-model="params.search_data" placeholder="请输入搜索关键词" show-action @search="searchFn" @cancel="onCancel" />
				</div>
			</div>

			<div class="list-box">
				<div class="li-cls" v-for="(item) in showList" :key="item.id" @click="goDetail(item.id)">
					<img class="li-img" :src="item.img_id" alt="">
					<div class="li-bottom">
						<div class="flex-1">
							<div class="name-cls ellipsis-text">{{item.title}}</div>
							<div class="click-num">点击数：{{item.click_num}}</div>
						</div>
						<i class="el-icon-right"></i>
					</div>
				</div>
				<van-empty image="search" description="暂无该产品,请等待产品上线~" v-if="!showList.length" />
			</div>

			<div class="page-cls">
				<van-pagination v-model="params.page" @change="changePage" :total-items="params.pagesize * pagenum" :items-per-page="params.pagesize" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				banner: [], // banner
				cateList: [], // 分类
				searchList: [], // 搜索类型
				params: {
					page: 1,
					pagesize: 10,
					ip_id: '',
					c_id: 0,
					type: 0,
					search_data: ''
				},
				showList: [],
				pagenum: null,
				show: false
			}
		},
		created() {
			this.params.ip_id = this.$route.params.id;
			this.getData();
			this.getProductList();
		},
		methods: {
			// 搜索
			searchFn() {
				this.params.page = 1;
				this.getProductList();
			},
			// 显示搜索栏
			showSearch() {
				this.show = !this.show;
			},
			// 关闭搜索
			onCancel() {
				this.show = false;
			},
			// 获取数据
			getData() {
				this.$api.getIpCate({
					ip_id: this.params.ip_id
				}).then(res => {
					if (res.code == '0000') {
						this.banner = res.data.banner_list;
						// 遍历数据，生成下拉组件数据
						res.data.cate.unshift({
							name: '全部',
							id: 0
						})
						res.data.cate.map(item => {
							item.text = item.name;
							item.value = item.id;
						})
						this.cateList = res.data.cate;
						// 遍历数据，生成下拉组件数据
						res.data.searchtype.forEach(item => {
							item.text = item.name;
							item.value = item.type;
						})
						this.searchList = res.data.searchtype;
					}
				})
			},
			// 获取产品列表
			getProductList() {
				this.$api.getIpProductList(this.params).then(res => {
					if (res.code == '0000') {
						this.pagenum = res.data.pagenum;
						this.showList = res.data.list;
					}
				})
			},
			// 跳转到详情
			goDetail(id) {
				this.$router.push({
					path: `/productDetail/${id}`
				})
			},
			changePage(e) {
				this.params.page = e;
				this.getProductList();
			}
		}
	}
</script>

<style scoped lang="scss">
	.product-box {

		.screen-box {
			background: #fff;
			height: 3.2rem;
			overflow: hidden;
			transition: all .3s;
			position: sticky;
			top: 3.6rem;

			.flex-box {
				display: flex;
				align-items: center;
			}

			.van-dropdown-menu {
				flex: 1;
			}

			.icon-search {
				width: 3rem;
				height: 3rem;
				line-height: 3rem;
				background: #ccc;
				color: #fff;
			}
		}

		.screen-box.show-search {
			height: 6rem;
		}

		.list-box {
			margin-top: 1rem;
			white-space: normal;
			text-align: left;
			padding: 0 0.6rem;

			.li-cls {
				width: 12rem;
				background: #FFFFFF;
				border-radius: 0.2rem;
				margin: 0 0.6rem 0.6rem 0;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
				display: inline-block;
				vertical-align: top;
				cursor: pointer;
				transition: all .3s;
			}

			.li-cls:nth-child(2n) {
				margin-right: 0;
			}

			.li-img {
				height: 10rem;
				width: 10rem;
				margin: 0.5rem;
				transition: all .3s;
			}

			.li-bottom {
				border-top: 1px solid #f7f7f7;
				padding: 0.4rem;
				display: flex;
				align-items: center;
			}

			.flex-1 {
				flex: 1;
			}

			.name-cls {
				font-size: 0.32rem;
				color: #666;
				width: 8rem;
				height: 1rem;
			}

			.click-num {
				font-size: 14px;
				color: #999;
			}

			.el-icon-right {
				font-size: 1rem;
				color: #ccc;
				padding: 0 0.5rem;
			}
		}
	}
	
	.page-cls{
		padding: 1rem 0 1.2rem;
	}
</style>
